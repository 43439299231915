<template lang="">
    <div>
        <v-container class="pa-2 d-flex justify-center">
            <v-col lg="4" sm="6" style="padding: 0px;">
                <br>
                <v-row class="d-flex justify-center align-center">
                    <v-img src="@/assets/img/checkmong/simple_azq/02-Eisai-logo1.png" contain max-height="50px" max-width="70px"></v-img>
                </v-row>
                <v-container class="">
                    <v-row class="d-flex align-center">
                        <v-col cols="2">
                            <v-btn elevation="0" style="margin-left: -17px;" fab color="#F3EFF7" @click="$router.go(-1)">
                                <v-icon>arrow_back_ios_new</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="8" class="d-flex justify-center align-center">
                            &nbsp;&nbsp;&nbsp;คำถามข้อที่ <v-chip class="ma-2" color="#F5DEED"><span style="color:#AE1B77;"><b>5</b>/14</span></v-chip>
                        </v-col>
                        <v-col cols="2"></v-col>
                    </v-row>
                    <v-row class="d-flex justify-center align-center">
                        <div style="width:92%;">
                            <v-progress-linear class="rounded" height="10" background-color="#EDC9E0" color="#CE72AB" value="28"></v-progress-linear>
                        </div>
                    </v-row>
                    <v-img style="transform: translate(0px,20px); z-index:2;" position="center center" src="@/assets/img/checkmong/simple_azq/5.png" contain max-height="140px" ></v-img>
                    <v-card class="xcard">
                        
                        <v-col cols="12" class="pt-0">
                            <div class="ml-2 mr-2">
                                <br>
                                <br>
                                <br>
                                <h2 class="mb-2">ลืมเรื่องที่ได้รับฟังมาเมื่อวานนี้ หรือ 2-3 วันก่อน</h2>
                                <v-radio-group v-model="ans" light active-class="radio-active">
                                    <v-radio class="transp-btn mb-5" label="ไม่เคยเกิดขึ้นเลย หรือเกิดนานๆ ครั้ง ในระยะเวลา 1 ปี" color="white" value="1"></v-radio>
                                    <v-radio class="transp-btn mb-5" label="เกิดขึ้นไม่บ่อยนัก หรือเกิด 1-2 ครั้ง ในระยะเวลา 1 เดือน" color="white" value="2"></v-radio>
                                    <v-radio class="transp-btn mb-5" label="เกิดขึ้นค่อนข้างบ่อยหรืออาจจะเกิดขึ้นเกือบทุกสัปดาห์" color="white" value="3"></v-radio>
                                    <v-radio class="transp-btn mb-5" label="เกิดขึ้นเกือบทุกวัน" color="white" value="4"></v-radio>
                                </v-radio-group>
                            </div>
                        </v-col>
                    </v-card>
    
                    <br>
                    <br>
                    <br>
    
                </v-container>
            </v-col>
        </v-container>
    </div>
    </template>
    
    <script>
    import {
        APIURL
    } from '@/constants'
    import Vue from 'vue'
    export default {
        data() {
            return {
                logo: require('@/assets/img/checkmong/landing-page/02-Eisai-เช็คหมอง.png'),
                nameRules: [
                    v => !!v || "โปรดกรอกข้อมูล",
    
                ],
                valid: true,
                is_btn_active: false,
                ans: null,
            };
        },
        watch: {
            ans(val) {
                if (val) {
                    console.log(val)
                    this.checkInput()
                } else {
                    console.log(val)
                }
            },
        },
    
        methods: {
            goNext() {
                this.$router.push('/simple_azq_6')
            },
            checkInput(){
                // setSimpleAzqAns
                const simple_azq_ans = this.$store.state.simple_azq_ans
                this.$store.commit('setSimpleAzqAns', {
                        ...simple_azq_ans,
                        'ans_5': this.ans
                    });
                setTimeout(() => {
                        this.goNext()
                }, 200)
                
            }
        },
        beforeCreate() {
    
        },
        mounted() {
    
        },
        created() {},
        beforeUpdate() {
    
        },
        updated() {
            //console.log('updated')
    
        }
    
    };
    </script>
    
    <style scoped>
    .footer-sm {
        position: absolute;
        bottom: 0;
        left: 0;
    }
    
    .btn-pink {
        background: #AE1B77 !important;
        color: white !important;
        box-shadow: 4px 1px 4px rgba(201, 200, 200, 0.25) !important;
        font-weight: bold !important;
    }
    
    .text-pink {
        color: #601550 !important;
    }
    
    .text-size {
        font-size: 18px;
    }
    
    .xcard {
        background: #FFFFFF;
        box-shadow: 4px 7px 10px rgba(255, 184, 184, 0.2);
        border-radius: 10px;
        color: #810E45;
        margin-top: -30px;
        z-index: 1;
    }
    
    .transp-btn {
        background: #FFFFFF;
        border: 1px solid #DDDDDD;
        border-radius: 9px;
        height: 70px;
        padding: 18px;
    }
    .radio-active {
        background: #BE4691;
        color: white !important;
    }
    </style>
    